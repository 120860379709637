import { ComponentProps, JSX } from "solid-js";
import c from "class-c";
import { PropsFor } from "solid-u";

import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import styles from "./Detail.module.scss";

declare namespace Detail {
  interface Props {
    label: JSX.Element;
    children: JSX.Element;
    variant?: "row" | "stacked";
    class?: string;
    propsFor?: PropsFor<{
      label?: ComponentProps<"header">;
      children: ComponentProps<"div">;
    }>;
  }
}

function Detail(_props: D<Detail.Props>) {
  const {
    label,
    variant,
    children,
    class: className,
    propsFor: _propsFor,
  } = Detail.PropsProvider.useMerge(_props) satisfies D;
  const propsFor = PropsFor.createHandler(() => _propsFor);

  return (
    <>
      {(() => {
        if (!variant)
          throw new Error(
            "Detail must be provided a variant either as a prop or through Detail.PropsProvider",
          );
      })()}
      <div
        class={c`
        ${c(styles)`
          detail
          variant:${variant}
        `}
        ${className}`}
      >
        <header {...propsFor("label")}>{label}</header>
        <div {...propsFor("children")}>{children}</div>
      </div>
    </>
  );
}

Detail.PropsProvider = createPropsProvider<Detail.Props>("Detail");

declare namespace Detail.Layout {
  type Props = ComponentProps<"div"> & {
    variant: "stacked-grid";
    children: JSX.Element;
  };
}

Detail.Layout = ({
  variant,
  class: className,
  children,
  ...props
}: D<Detail.Layout.Props>) => {
  return (
    <div
      class={c`
        ${styles[`detail-layout:${variant}`]}
        ${className}
      `}
      {...props}
    >
      <Detail.PropsProvider
        variant={({ "stacked-grid": "stacked" } as const)[variant]}
      >
        {children}
      </Detail.PropsProvider>
    </div>
  );
};

export default Detail;
